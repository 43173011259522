import axios from "@/axios"

const url = "api/specimens/"
export class SpecimenService {
	async updateToggleRanSpecimen(body) {
		const data = await axios.put(`${url}toggle-ran`, body)
		return data
	}

	async store(body) {
		try {
			return (await axios.post(`${url}store`, body)).data
		} catch (error) {
			throw error
		}
	}

	async getCountersByCategory(body) {
		const data = await axios.get(`${url}counters-by-category`, { params: { in_galpon: body } })
		return data
	}
	async getInactivesCountersByCategory() {
		const { data } = await axios.get(`${url}get-counters-specimens-inactives`)
		return data
	}

	async updateCategorySpecimen(body) {
		const data = await axios.put(`${url}update-category-by-id`, body)
		return data
	}

	async getColorsSpecimen() {
		const data = await axios.get(`${url}get-colors`)
		return data
	}

	async getOriginsSpecimen(body) {
		const data = await axios.get(`${url}get-origins`, body)
		return data
	}

	async updatePlateSpecimen(body) {
		const data = await axios.put(`${url}update-plate-additional-by-id`, body)
		return data
	}

	async getIdToUpdateVideo({ specimen_id }) {
		try {
			const { status, data } = await axios.get(`${url}to-update-video/${specimen_id}`)
			return { status, data }
		} catch (error) {
			const { status, response } = err
			return { status, message: response.data.message }
		}
	}

	async getSpecimensToFight(search) {
		const data = await axios.get(`${url}get-specimens-to-fight`, { params: { search } })
		return data
	}

	async getChikcsByName(search) {
		const data = await axios.get(`${url}by-name/get-chicks-by-correlative`, { params: { search } })
		return data
	}

	/**
	 * @param {number} specimen_id The specimen id
	 *
	 * @return {Promise<Object<string, any>>}
	 */
	async fetchSpecimenById(specimen_id) {
		try {
			const { data: response } = await axios.get(`${url}${specimen_id}`)
			return response
		} catch (error) {
			throw error
		}
	}

	async getAliasSpecimensDeads() {
		try {
			const { data } = await axios.post(`${url}get-alias-specimens-deads`)

			return data
		} catch (error) {
			throw error
		}
	}

	async updateDefinition({ specimen_id, definition }) {
		try {
			const { data } = await axios.post(`${url}update-definition-plate`, { specimen_id, definition })
			return data
		} catch (error) {
			throw error
		}
	}

	async globalSearch(filter) {
		const data = await axios.get(`${url}global-search`, { params: { campo: filter } })
		return data
	}
	async saveSpecimenWeight(payload) {
		const data = await axios.post(`${url}save-specimen-weight`, payload)
		return data
	}
	async getSpecimenWeightTracking(payload) {
		return await axios.post(`${url}get-specimen-weight-tracking`, payload)
	}
	async updateSpecimenWeightTracking(payload) {
		const data = await axios.post(`${url}update-specimen-weight-tracking`, payload)
		return data
	}
	async getAllStatusSpecimens(category_id = null) {
		return (await axios.get(`${url}get-all-status-specimen`, { params: { category_id } })).data
	}
	async getAvailableSpecimens(body) {
		return (
			await axios.get(`${url}get-available-specimens`, {
				params: { category_id: body },
			})
		).data
	}
	async saveStatusWeight(body) {
		return await axios.post(`${url}save-status-weight`, body)
	}
	async saveStatusMassively(body) {
		try {
			return await axios.post(`${url}save-status-massively`, body)
		} catch (error) {
			throw error
		}
	}
	async getCategoriesSpecimens(specimen_id = null) {
		const resp = await axios.get(`${url}get-categories-specimens/${specimen_id !== null ? specimen_id : 0}`)
		return resp
	}

	async getColors({ gender_specimen, perpage, page, search }) {
		try {
			const { data } = await axios.get(`${url}get-colors`, {
				params: {
					gender_specimen,
					perpage,
					page,
					search,
				},
			})
			return data
		} catch (error) {
			throw error
		}
	}
	async getSpecimensByCategory(body) {
		try {
			return (await axios.post(`${url}by-category`, body)).data
		} catch (error) {
			throw error
		}
	}
	async addImage(body) {
		try {
			return await axios.post(`${url}add-image`, body)
		} catch (error) {
			throw error
		}
	}
	async updateCategorySpecimen(body) {
		try {
			return await axios.put(`${url}update-category-specimen`, body)
		} catch (error) {
			throw error
		}
	}
	async updateOriginSpecimen(body) {
		try {
			return await axios.put(`${url}update-origin-specimen`, body)
		} catch (error) {
			throw error
		}
	}
	async updateColorSpecimen(body) {
		try {
			return await axios.put(`${url}update-color-specimen`, body)
		} catch (error) {
			throw error
		}
	}
	async updateBreederSpecimen(body) {
		try {
			return await axios.put(`${url}update-breeder-specimen`, body)
		} catch (error) {
			throw error
		}
	}
	async updateAliasSpecimen({ specimen, trackingSpecimen }) {
		try {
			return await axios.put(`${url}update-alias-specimen`, { specimen, trackingSpecimen })
		} catch (error) {
			throw error
		}
	}
	async updateSpecimenFields({ specimen, trackingSpecimen }) {
		try {
			return await axios.put(`${url}update-field`, { specimen, trackingSpecimen })
		} catch (error) {
			throw error
		}
	}
	async updateAdditionalPlateSpecimen(body) {
		try {
			return await axios.put(`${url}update-additional-plate-specimen`, body)
		} catch (error) {
			throw error
		}
	}
	async updatePreaparadorSpecimen(body) {
		try {
			return await axios.put(`${url}update-preparador-specimen`, body)
		} catch (error) {
			throw error
		}
	}
	async updatePriceSpecimen(body) {
		try {
			return await axios.put(`${url}update-price-specimen`, body)
		} catch (error) {
			throw error
		}
	}
	async updateObservationsSpecimen(body) {
		try {
			return await axios.put(`${url}update-observations-specimen`, body)
		} catch (error) {
			throw error
		}
	}
	async updateDobSpecimen(body) {
		try {
			return (await axios.put(`${url}update-dob-specimen`, body)).data
		} catch (error) {
			throw error
		}
	}
	async updatePadrilloSpecimen(body) {
		try {
			return (await axios.put(`${url}update-padrillo-specimen`, body)).data
		} catch (error) {
			throw error
		}
	}
	async updateMadrillaSpecimen(body) {
		try {
			return (await axios.put(`${url}update-madrilla-specimen`, body)).data
		} catch (error) {
			throw error
		}
	}
	async insertTrakingFieldsSpecimen(body) {
		try {
			return (await axios.post(`${url}insert-tracking-fields-specimen`, body)).data
		} catch (error) {
			throw error
		}
	}
	async getTrakingFieldsSpecimen(body) {
		try {
			return (
				await axios.get(`${url}get-tracking-fields-specimen`, {
					params: body,
				})
			).data
		} catch (error) {
			throw error
		}
	}

	async getTypeWeights() {
		try {
			return (await axios.get(`${url}get-type-weights`)).data
		} catch (error) {
			throw error
		}
	}

	async addTypeWeight(body) {
		try {
			return (await axios.post(`${url}add-type-weight`, body)).data
		} catch (error) {
			throw error
		}
	}
	async getSpecimensToSelect(body) {
		try {
			return (await axios.get(`${url}get-specimens-to-select`, { params: body })).data
		} catch (error) {
			throw error
		}
	}
	async saveMassivelyWeights(body) {
		try {
			return (await axios.post(`${url}save-massively-weights`, body)).data
		} catch (error) {
			throw error
		}
	}
	async saveMassivelyPhotos(body) {
		try {
			return (await axios.post(`${url}save-massively-photos`, body)).data
		} catch (error) {
			throw error
		}
	}

	async updatePlate({ plate, specimen_id }) {
		try {
			const { data } = await axios.put(`${url}update-plate`, { plate, specimen_id })
			return data
		} catch (error) {
			throw error
		}
	}

	async getCounterParentsDead(category_id) {
		try {
			const { data } = await axios.get(`/api/deads/count-deads-by-category/${category_id}`)
			return data
		} catch (error) {
			throw error
		}
	}

	async getPosturasByIdSpecimen(payload) {
		const { data } = await axios.post("/api/postura/get-by-id-specimen", payload)
		return data
	}

	async getPosturasByIdLote(payload) {
		const { data } = await axios.post("/api/postura/get-by-id-lote", payload)
		return data
	}

	async getSonsByIdSpecimenByGender({ specimen_id, date_start, date_end }) {
		const { data } = await axios.post("/api/postura/get-sons-by-id-specimen-by-gender", {
			specimen_id,
			date_start,
			date_end,
		})
		return data
	}

	async getSonsGlobal(specimen_id) {
		try {
			const { data } = await axios.get(`${url}get-sons-global/${specimen_id}`)
			return data
		} catch (error) {
			return error.response.data
		}
	}

	async updateFieldSpecimen({ specimen_id, key, value_id, value_text, currency }) {
		try {
			const { data } = await axios.put(`${url}update-field-specimen`, {
				specimen_id,
				key,
				value_id,
				value_text,
				currency,
			})
			return data
		} catch (error) {
			return error.response.data
		}
	}

	async getTrackingDashboard({ specimen_id, key }) {
		try {
			const { data } = await axios.get(`${url}get-tracking-by-field`, {
				params: {
					specimen_id,
					key,
				},
			})
			return data
		} catch (error) {
			return error.response.data
		}
	}

	async saveManySpecs({ arraySpecs }) {
		try {
			const { data } = await axios.post(`${url}save-many`, { arraySpecs })
			return data
		} catch (error) {
			return error.response.data
		}
	}

	async getSiblings({ specimen_id }) {
		try {
			const { data } = await axios.get(`${url}get-family-tree/brothers`, { params: { specimen_id } })
			return data
		} catch (error) {
			return error.response.data
		}
	}

	async deleteColourSpecimen({ color_id }) {
		try {
			const { data } = await axios.delete(`${url}delete-colour-specimen`, { params: { color_id } })
			return data
		} catch (error) {
			return error.response.data
		}
	}

	async updateColourSpecimen({ color_id, gender_id }) {
		try {
			const { data } = await axios.put(`${url}update-colour-specimen`, {
				color_id,
				gender_id,
			})
			return data
		} catch (error) {
			return error.response.data
		}
	}

	async updateNameColourSpecimen({ color_id, color_text }) {
		try {
			const { data } = await axios.put(`${url}update-name-colour-specimen`, {
				color_id,
				color_text,
			})
			return data
		} catch (error) {
			return error.response.data
		}
	}

	async createColourSpecimen({ gender_id, name }) {
		try {
			const { data } = await axios.post(`${url}create-colour-specimen`, {
				gender_id,
				name,
			})
			return data
		} catch (error) {
			return error.response.data
		}
	}

	async getCounterBrothersAndGender({ specimen_id }) {
		try {
			const { data } = await axios.get(`${url}get-counter-brothers-and-gender`, {
				params: {
					specimen_id,
				},
			})
			return data
		} catch (error) {
			return error.response.data
		}
	}

	async spGetSpecimensToPublic({ search = null }) {
		try {
			const { data } = await axios.get(`${url}get-specimens-public`, {
				params: {
					search,
				},
			})
			return data
		} catch (error) {
			return error.response.data
		}
	}

	async updateChangeToPublicSpecimen({ specimen_id, check }) {
		console.log("ADD", specimen_id, check)
	}

	//
	async getClinicalDeworns({ specimen_id }) {
		const { data } = await axios.get(`${url}get-specimen-deworming/${specimen_id}`)
		return data
	}

	async getClinicalVitamins({ specimen_id }) {
		const { data } = await axios.get(`${url}get-specimen-vitamins/${specimen_id}`)
		return data
	}

	async getClinicalTreatments({ specimen_id }) {
		const { data } = await axios.get(`${url}get-treatments-history-dashboard/${specimen_id}`)
		return data
	}

	async insertClinicalDeworn({ specimen_id }) {
		const { data } = await axios.post(`${url}store-deworn/${specimen_id}`)
		return data
	}

	async insertClinicalVitamins({ specimen_id, vitamins }) {
		const { data } = await axios.post(`${url}store-vitamins-massive`, {
			specimen_id,
			vitamins,
		})
		return data
	}

	async getClinicalCounters({ specimen_id }) {
		const { data } = await axios.get(`${url}counter-medical-history/${specimen_id}`)
		return data
	}

	async getBreederOpts({ search }) {
		const { data } = await axios.post(`api/participants/get-toggle`, {
			pageIn: 1,
			perPageIn: 50,
			search,
			status: 0,
			type_id: 2,
		})
		return data
	}

	async updateBreederLine({ specimen_id, lines }) {
		const { data } = await axios.post(`${url}line/update`, {
			specimen_id,
			lines,
		})
		return data
	}

	async getSpecimenBreederLines({ specimen_id }) {
		const { data } = await axios.get(`${url}line/get-line-by-specimen/${specimen_id}`)
		return data
	}

	async insertParentSimple({ plate, color_id, category_id }) {
		const { data } = await axios.post(`${url}store-father-simple`, {
			plate,
			color_id,
			category_id,
		})
		return data
	}

	async changeStatusCarnivorous({ specimens, type, status = null }) {
		const { data } = await axios.put(`${url}change-carnivorous-specimens`, {
			specimens,
			type,
			status,
		})
		return data
	}

	async trackingCarnivorous({ specimen_id, type }) {
		const { data } = await axios.get(`${url}carnivorous-specimen-tracking/${specimen_id}`, {
			params: {
				type,
			},
		})
		return data
	}

	async trackingFertility({ specimen_id }) {
		const { data } = await axios.get(`${url}get-average-fertilidad-history/${specimen_id}`)
		return data
	}

	async searchSpecimenToClinical({ search }) {
		const { data } = await axios.get(`${url}get-specimens-to-hc-masive/${search}`)
		return data
	}

	async insertMassiveDeworn({ specimens }) {
		const { data } = await axios.post(`${url}insert-specimen-deworn-massive`, {
			specimens,
		})
		return data
	}

	async insertMassiveVitamin({ specimens, vitamin_id, type, days }) {
		const { data } = await axios.post(`${url}insert-masive-vitamins`, {
			specimens,
			vitamin_id,
			type,
			days,
		})
		return data
	}

	async insertMassiveDewornByCategory({ category_id }) {
		const { data } = await axios.post(`${url}store-deworning-total-by-category`, {
			category_id,
		})
		return data
	}

	async insertMassiveVitaminByCategory({ category_id, vitamin_id, type, days }) {
		const { data } = await axios.post(`${url}store-vitamins-total-by-category`, {
			category_id,
			vitamin_id,
			type,
			days,
		})
		return data
	}

	async removeSpecimenImage({ specimen_id, id }) {
		const { data } = await axios.delete(`${url}delete-image-gallery`, {
			params: { specimen_id, id },
		})
		return data
	}

	async getTopesList({ specimen_id }) {
		const { data } = await axios.get(`${url}get-topes-by-specimen/${specimen_id}`)
		return data
	}

	// main dashboard
	async getMainDsYears() {
		const { data } = await axios.get(`${url}dashboard/years`)
		return data
	}

	async getMainDsGender({ start_date, end_date }) {
		const { data } = await axios.get(`${url}dashboard/gender`, {
			params: { start_date, end_date },
		})
		return data
	}

	async getMainDsOrigin({ start_date, end_date }) {
		const { data } = await axios.get(`${url}dashboard/origin`, {
			params: { start_date, end_date },
		})
		return data
	}

	async getMainDsStatus({ start_date, end_date }) {
		const { data } = await axios.get(`${url}dashboard/status`, {
			params: { start_date, end_date },
		})
		return data
	}

	async getMainDsCategory({ start_date, end_date }) {
		const { data } = await axios.get(`${url}dashboard/category`, {
			params: { start_date, end_date },
		})
		return data
	}

	async getMainDsAge({ start_date, end_date }) {
		const { data } = await axios.get(`${url}dashboard/dob`, {
			params: { start_date, end_date },
		})
		return data
	}

	async getMainDsDead({ start_date, end_date, gender, page }) {
		const { data } = await axios.get(`${url}dashboard/deads`, {
			params: { start_date, end_date, gender, page },
		})
		return data
	}

	async getMainDsLine({ start_date, end_date, percentage, gender, page }) {
		const { data } = await axios.get(`${url}dashboard/line`, {
			params: { start_date, end_date, percentage, gender, page },
		})
		return data
	}

	async getMainDsRace({ start_date, end_date, percentage, gender, page }) {
		const { data } = await axios.get(`${url}dashboard/race`, {
			params: { start_date, end_date, percentage, gender, page },
		})
		return data
	}

	async getMainDsColor({ start_date, end_date, gender, page }) {
		const { data } = await axios.get(`${url}dashboard/color`, {
			params: { start_date, end_date, gender, page },
		})
		return data
	}

	async getMainDsCounter({ start_date, end_date }) {
		const { data } = await axios.get(`${url}dashboard/counter`, {
			params: { start_date, end_date },
		})
		return data
	}

	async getMeasurements({ id }) {
		const { data } = await axios.get(`${url}get-measurements-by-specimen/${id}`)
		return data
	}

	async insertMeasurements({ measurements }) {
		const { data } = await axios.post(`${url}store-measurements-by-specimen`, { measurements })
		return data
	}

	async getMeasureTracking({ id }) {
		const { data } = await axios.get(`${url}get-measurements-by-specimen-tracking/${id}`)
		return data
	}

	async getRestingPadrillos({ search, page, perpage }) {
		const { data } = await axios.get(`${url}get-specimens-in-rest`, {
			params: { search, page, perpage },
		})
		return data
	}

	async getRestingTracking({ specimen_id, start_date, end_date }) {
		const { data } = await axios.get(`${url}rests-tracking`, {
			params: { specimen_id, start_date, end_date },
		})
		return data
	}

	async getBrothers({ specimen_id, filter_by }) {
		const { data } = await axios.get(`${url}get-family-tree/half-brothers`, {
			params: { specimen_id, filter_by },
		})
		return data
	}

	async getNephews({ specimen_id }) {
		const { data } = await axios.get(`${url}get-family-tree/nephews`, {
			params: { specimen_id },
		})
		return data
	}

	async getChildrem({ specimen_id }) {
		const { data } = await axios.get(`${url}get-family-tree/childs`, {
			params: { specimen_id },
		})
		return data
	}

	async getSpecimensWithDaysWithoutTopes({ search, page, perpage, order, orderBy, status, category }) {
		try {
			const { data } = await axios.get(`${url}days-without-tope`, {
				params: {
					search,
					page,
					perpage,
					order,
					orderBy,
					status,
					category,
				},
			})
			return data
		} catch (error) {
			return error.response.data
		}
	}

	async getCounterDaysWithoutTopes() {
		const { data } = await axios.get(`${url}days-without-tope/counter`)
		return data
	}
}

export default new SpecimenService()
