import decrestService from "@/services/header/decrest.service"
import RecommendedSpecimenService from "@/services/preparacion/events/recommendedSpecimen.service.js"
import SpecimenService from "@/services/specimens.service.js"
import PostureService from "@/services/posturas.service.js"

export default {
	namespaced: true,
	state: {
		S_DECREST_COUNTER: 0,
		S_RECOMMENDED_COUNTER: 0,
		S_DAYS_WITHOUT_TOPES_COUNTER: 0,
		S_CHECK_MADRILLAS_COUNTER: 0,
	},
	getters: {
		G_GET_DECREST_COUNTER(state) {
			return state.S_DECREST_COUNTER
		},
		G_GET_RECOMMENDED_COUNTER(state) {
			return state.S_RECOMMENDED_COUNTER
		},
		G_GET_DAYS_WITHOUT_TOPES_COUNTER(state) {
			return state.S_DAYS_WITHOUT_TOPES_COUNTER
		},
		G_GET_CHECK_MADRILLAS_COUNTER(state) {
			return state.S_CHECK_MADRILLAS_COUNTER
		},
	},
	mutations: {
		M_UPDATE_COUNTER(state, payload) {
			state.S_DECREST_COUNTER = payload
		},
		M_UPDATE_COUNTER_RECOMMENDED(state, payload) {
			state.S_RECOMMENDED_COUNTER = payload
		},
		M_UPDATE_DAYS_WITHOUT_TOPES_COUNTER(state, payload) {
			state.S_DAYS_WITHOUT_TOPES_COUNTER = payload
		},
		M_UPDATE_CHECK_MADRILLAS_COUNTER(state, payload) {
			state.S_CHECK_MADRILLAS_COUNTER = payload
		},
	},
	actions: {
		async A_GET_DECREST_COUNTER({ commit }) {
			const { data } = await decrestService.getDecrestedCounters()
			let value = data.find((c) => c.id == 1).counter
			commit("M_UPDATE_COUNTER", value)
		},
		async A_GET_RECOMMENDED_COUNTER({ commit }, role) {
			const { data } = await RecommendedSpecimenService.getRecommendedCounter(role)
			let value = data.data.counter
			commit("M_UPDATE_COUNTER_RECOMMENDED", value)
		},
		async A_GET_DAYS_WITHOUT_TOPES_COUNTER({ commit }) {
			const { data } = await SpecimenService.getCounterDaysWithoutTopes()
			let value = data
			commit("M_UPDATE_DAYS_WITHOUT_TOPES_COUNTER", value)
		},
		async A_GET_CHECK_MADRILLAS_COUNTER({ commit }) {
			const { data } = await PostureService.getCounterMadrillasWithoutEggs()
			let value = data
			commit("M_UPDATE_CHECK_MADRILLAS_COUNTER", value)
		},
	},
}
