<template>
	<div
		v-if="open"
		style="z-index: 9999; background-color: rgba(30, 30, 30, 0.9)"
		class="container-sliders"
		:class="[open ? '' : 'none']"
	>
		<div class="container-images modal-container">
			<img
				onerror="this.src=`/rooster/${isSimple?'no_image.jpg':'rooster.jpg'}`"
				v-if="type == 'image'"
				:src="url"
				alt=""
			/>

			<template v-else>
				<div
					v-if="!loadingVideo"
					class="mt-1 w-100 content-video"
					style="position: relative; padding-top: 56.25%"
				>
					<iframe
						:src="`https://iframe.videodelivery.net/${url}?autoplay=true`"
						autoplay
						style="border: none; position: absolute; top: 0; height: 100%; width: 100%"
						allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
						allowfullscreen="true"
						id="stream-player"
					></iframe>
				</div>
			</template>
			<div class="content-file">
				<CargandoAnimation></CargandoAnimation>
			</div>
			<div class="container-link" v-if="!isSimple">
				<router-link
					v-if="idSpecimen"
					:to="{ name: 'detalle-ejemplar', params: { specimen_id: idSpecimen } }"
					target="_blank"
				>
					{{ text }}
				</router-link>
				<a v-else :href="text_url" target="_blank">
					<feather-icon icon="LinkIcon" size="24" />
					{{ text }}
				</a>
			</div>
			<div v-else class="container-link">
				{{ text }}
			</div>
			<div class="container-btn">
				<feather-icon @click="cerrarContainer" icon="XIcon" size="40" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import CargandoAnimation from "./CargandoAnimation.vue"

export default {
	name: "SlidersImageVideo",
	components: { CargandoAnimation },
	props: {
		url: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			required: true,
		},
		text: {
			type: String,
			required: true,
		},
		text_url: {
			type: String,
			required: true,
		},
		open: {
			type: Boolean,
			required: true,
		},
		idSpecimen: {
			type: Number,
			required: true,
		},
		isSimple: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			imageSelect: null,
			loadingVideo: false,
		}
	},
	computed: {
		...mapState("header_book", [
			"images",
			"imagesVideoteca",
			"openModal",
			"indexImage",
			"videoteca",
			"toggleOpenGallery",
			"id_specimen",
		]),
	},
	methods: {
		...mapActions("header_book", ["setImages", "toggleModal", "setIndexImage", "setTextImage"]),
		cerrarContainer() {
			this.imageSelect = null
			this.$emit("refreshSlider")
		},
	},
	watch: {},
}
</script>

<style lang="scss">
.container-sliders {
	//background: rgba(30, 30, 30, 0.9);

	//z-index: 10000;
	width: 100%;
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	.container-images {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		img {
			object-fit: contain;
			z-index: 500;
			height: 90% !important;
			width: 90% !important;
			z-index: 500;
			@media screen and (min-width: 600px) {
				height: 50% !important;
				width: 50% !important;
			}
			@media screen and (min-width: 1000px) {
				height: 700px !important;
				width: 700px !important;
			}
		}
		video {
			object-fit: contain;
			z-index: 500;
		}
		.content-video {
			height: 190px !important;
			width: 320px !important;
			z-index: 9999 !important;
			// background: orange;
			@media screen and (min-width: 400px) {
				height: 180px !important;
				width: 420px !important;
			}
			@media screen and (min-width: 500px) {
				height: 320px !important;
				width: 520px !important;
			}
			@media screen and (min-width: 600px) {
				height: 400px !important;
				width: 620px !important;
			}
			@media screen and (min-width: 800px) {
				height: 480px !important;
				width: 820px !important;
			}
			@media screen and (min-width: 1000px) {
				height: 500px !important;
				width: 1000px !important;
			}

			@media screen and (min-width: 1800px) {
				height: 560px !important;
				width: 1200px !important;
			}
			iframe {
				height: 100%;
				width: 100%;
			}
		}
		.container-link {
			position: absolute;
			bottom: 3rem;
			left: 50%;
			margin-top: 0.5rem;
			transform: translateX(-50%);
			a {
				width: 500px;
				display: flex;
				align-items: center;
				justify-content: center;
				svg {
					margin-right: 1rem;
					margin-bottom: 0.3rem;
				}
				font-size: 16px;
				font-weight: bold;
				@media screen and (min-width: 600px) {
					font-size: 20px;
				}
			}
			z-index: 500;
		}
		.container-btn {
			position: absolute;
			right: 40px;
			top: 40px;
			width: 100%;
			display: flex;
			justify-content: flex-end;
			cursor: pointer;
			z-index: 500;
		}
		.content-file {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			p {
				margin-bottom: 0;
				font-size: 40px;
				font-weight: bold;
				color: #fff;
			}
		}
	}
}
.modal-container {
	overflow-y: none !important;

	height: auto !important;
}
.none {
	display: none;
}
stream {
	&:first-child {
		// background-color: red !important;
	}
}
</style>
